import * as React from "react";
//import RichTextInput from 'ra-input-rich-text';
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  required,
  Datagrid,
  TextField,
  DateField,
  EditButton,
} from "react-admin";
import { List, BooleanField, ReferenceField } from "react-admin";

export const AssetTypeList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="description" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);

export const AssetTypeCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="description" />
      <DateInput
        source="createdAt"
        defaultValue={new Date()}
        parse={(value) => new Date(value)}
      />
      <DateInput
        source="updatedAt"
        defaultValue={new Date()}
        parse={(value) => new Date(value)}
      />
    </SimpleForm>
  </Create>
);

export const AssetTypeEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="description" />
      <DateInput source="createdAt" parse={(value) => new Date(value)} />
      <DateInput source="updatedAt" parse={(value) => new Date(value)} />
    </SimpleForm>
  </Edit>
);
