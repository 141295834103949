import * as React from "react";
import { fetchUtils, Admin, Resource, ListGuesser } from "react-admin";
import jsonServerProvider from "ra-data-json-server";
import authProvider from "./AuthProvider";
import environment from "./environment";
import { AssetList, AssetEdit, AssetCreate } from "./views/assets";
import {
  AssetTypeList,
  AssetTypeEdit,
  AssetTypeCreate,
} from "./views/asset-types";
import lb4Provider from "./data-providers/react-admin-lb4";

const httpClient = (url, options = {}) => {
  console.log(url);
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  if (localStorage.getItem("auth")) {
    const { token } = JSON.parse(localStorage.getItem("auth"));
    options.headers.set("Authorization", `Bearer ${token}`);
  }
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = lb4Provider(environment.backend_url, httpClient);
const App = () => (
  <Admin authProvider={authProvider} dataProvider={dataProvider}>
    <Resource
      name="assets"
      list={AssetList}
      create={AssetCreate}
      edit={AssetEdit}
    />
    <Resource
      name="asset-types"
      list={AssetTypeList}
      create={AssetTypeCreate}
      edit={AssetTypeEdit}
    />
  </Admin>
);

export default App;
