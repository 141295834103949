import { AUTH_LOGIN } from "react-admin";
import environment from "./environment";

const AuthProvider = {
  login: ({ username, password }) => {
    const request = new Request(environment.backend_url + "/users/login", {
      method: "POST",
      body: JSON.stringify({ email: username, password: password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((auth) => {
        console.log("token ist " + JSON.stringify(auth));
        localStorage.setItem("auth", JSON.stringify(auth));
      });
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      console.log("Remove auth token localstorge");
      localStorage.removeItem("auth");
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  checkAuth: () =>
    localStorage.getItem("auth") ? Promise.resolve() : Promise.reject(),
  logout: () => Promise.resolve(),
  getIdentity: () => Promise.resolve(),
  // authorization
  getPermissions: (params) => Promise.resolve(),
};

export default AuthProvider;
