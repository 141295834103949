const environment = (() => {
  switch (window.location.host) {
    case "admin.trollforge.com":
      return {
        backend_url: "https://api.trollforge.com",
      };
    case "localhost:9004":
    default:
      return {
        backend_url: "http://localhost:9002",
      };
  }
})();

export default environment;
