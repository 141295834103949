import * as React from "react";
//import RichTextInput from 'ra-input-rich-text';
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  required,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  FileInput,
  FileField,
} from "react-admin";
import { List, BooleanField, ReferenceField } from "react-admin";

export const AssetList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField source="assetTypeId" reference="asset-types">
        <TextField source="id" />
      </ReferenceField>
      <TextField source="name" />
      <TextField source="description" />
      <TextField source="config" />
      <TextField source="file" />
      <TextField source="thumbnail" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);

export const AssetCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <ReferenceInput
        label="Asset Type"
        source="assetTypeId"
        reference="asset-types"
        validate={[required()]}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput multiline source="description" />
      <TextInput multiline source="config" />
      <FileInput source="file" label="file" accept="application/obj">
        <FileField source="src" title="title" />
      </FileInput>
      <TextInput source="thumbnail" />
      <DateInput
        source="createdAt"
        defaultValue={new Date()}
        parse={(value) => new Date(value)}
      />
      <DateInput
        source="updatedAt"
        defaultValue={new Date()}
        parse={(value) => new Date(value)}
      />
    </SimpleForm>
  </Create>
);

export const AssetEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <ReferenceInput
        label="Asset Type"
        source="assetTypeId"
        reference="asset-types"
        validate={[required()]}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput multiline={true} source="description" />
      <TextInput multiline={true} source="config" />
      <FileInput source="file" label="file" accept="application/obj">
        <FileField
          onChange={(value, v2) => {
            console.log("change" + value);
            console.log(value);
            console.log(v2);
          }}
          onClick={(value, v2) => {
            console.log("click" + value);
            console.log(value);
            console.log(v2);
          }}
          source="name"
          title="title"
        />
      </FileInput>
      <TextInput source="thumbnail" />
      <DateInput
        source="createdAt"
        parse={(value) => new Date(value)}
        disabled
      />
      <DateInput
        source="updatedAt"
        defaultValue={new Date()}
        parse={(value) => new Date(value)}
        disabled
      />
    </SimpleForm>
  </Edit>
);
